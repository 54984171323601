import { usePathname } from 'next/navigation'
import { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import MenuLink from 'containers/Menu/MenuLink'
import classes from './styles.module.scss'
import type { LinkObject } from '../utils'
import getLinks from '../utils'

import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { usePageViewTracker } from '@patrianna/shared-hooks'
import { trackEvent } from 'config/analytic'

type MenuAccordionProps = {
  linkObject: LinkObject
  toggleMenu?: (isOpen: boolean) => void
}

export function MenuAccordion({ linkObject, toggleMenu }: MenuAccordionProps) {
  const pathname = usePathname()
  const t = useTranslation()
  const { GAEventMenuParams } = useGetGAEventMenuParams()
  const [trackedEvents, setTrackedEvents] = useState(new Set())

  const links = getLinks(linkObject.links)

  const linksUrls = links.map((link) => link.link)

  const isActive = linksUrls.includes(pathname)

  const [isOpened, setIsOpened] = useState(isActive)

  usePageViewTracker(() => setTrackedEvents(new Set()))

  const menuToggle = () => {
    const event = isOpened ? 'clicked_on_categories_close_menu' : 'clicked_on_categories_open_menu'
    setIsOpened(!isOpened)
    setTrackedEvents((prevState) => {
      prevState.add(event)

      return prevState
    })
    // both events should be sent only once per url change
    if (
      !trackedEvents.has('clicked_on_categories_open_menu') ||
      !trackedEvents.has('clicked_on_categories_close_menu')
    ) {
      trackEvent(event, GAEventMenuParams)
    }
  }

  return (
    <div>
      <button
        aria-expanded={isOpened}
        className={cx(classes.menuAccordion, isActive || (isOpened && classes.menuAccordionActive))}
        onClick={menuToggle}
      >
        <div className={cx(classes.menuAccordionTitleBlock)}>
          <img className={classes.menuAccordionIcon} src={linkObject.icon} alt={`${linkObject.title} menu icon`} />
          {t(`common.${linkObject.title}`)}
        </div>
        <div className={cx(classes.menuAccordionTitleBlock, classes.arrow)}>
          {isOpened ? (
            <img src={`${process.env.IMG_ORIGIN}/menu/arrow_up.svg`} alt='accordion arrow up' />
          ) : (
            <img src={`${process.env.IMG_ORIGIN}/menu/arrow_down.svg`} alt='accordion arrow down' />
          )}
        </div>
      </button>
      <div className={cx(classes.menuAccordionContent, { [classes.expanded]: isOpened })}>
        {links.map((i) => (
          <MenuLink
            {...i}
            key={i.id}
            onClick={() => {
              toggleMenu?.(false)
              i?.onClick?.()
            }}
          />
        ))}
      </div>
    </div>
  )
}
