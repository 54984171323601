import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'

function MaintenanceMessage() {
  const t = useTranslation()

  return (
    <div className={cx(classes.root, 'mt-paper')}>
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 24 24' className={classes.img}>
        <path d='M22.61,19L13.53,9.91C14.46,7.57 14,4.81 12.09,2.91C9.79,0.61 6.21,0.4 3.66,2.26L7.5,6.11L6.08,7.5L2.25,3.69C0.39,6.23 0.6,9.82 2.9,12.11C4.76,13.97 7.47,14.46 9.79,13.59L18.9,22.7C19.29,23.09 19.92,23.09 20.31,22.7L22.61,20.4C23,20 23,19.39 22.61,19M19.61,20.59L10.15,11.13C9.54,11.58 8.86,11.85 8.15,11.95C6.79,12.15 5.36,11.74 4.32,10.7C3.37,9.76 2.93,8.5 3,7.26L6.09,10.35L10.33,6.11L7.24,3C8.5,2.95 9.73,3.39 10.68,4.33C11.76,5.41 12.17,6.9 11.92,8.29C11.8,9 11.5,9.66 11.04,10.25L20.5,19.7L19.61,20.59Z' />
      </svg>

      <h4>{t('common.site_might_not_work_properly')}</h4>
    </div>
  )
}

export default MaintenanceMessage
