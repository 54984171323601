import cx from 'classnames'
import { useAppSelector } from 'src/store/hooks'
import { getGoldCurrencySelector } from 'store/modules/currencies/selectors'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'
import BalanceAnimation from './BalanceAnimation'

function GoldCurrency({ currencyClassName }: { currencyClassName?: string }) {
  const goldCurrency = useAppSelector(getGoldCurrencySelector)
  const t = useTranslation()

  if (goldCurrency) {
    return (
      <div className={cx('mt-typography-caption', classes.currencyText, currencyClassName)}>
        <img
          src={`${process.env.IMG_ORIGIN}/menu/gold-coin-cherry.svg`}
          className={classes.icon}
          alt='gold coin icon'
        />
        <span>
          {t('common.gold_coin_gc')} <BalanceAnimation amount={goldCurrency.amount} />
        </span>
      </div>
    )
  }

  return null
}

export default GoldCurrency
