import { useEffect, useRef } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'

type Props = {
  value: string
  className?: string
  containerClassName?: string
  currentStep?: number
  nextStep?: number
}

const PROGRESS_PROPERTY = '--progress'

function ProgressBar({ className, value, containerClassName, currentStep, nextStep }: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  const t = useTranslation()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty(PROGRESS_PROPERTY, `${value}%`)
    }
  }, [value])

  return (
    <>
      <div className={classes.progressBarHolder}>
        <div ref={containerRef} className={cx(classes.progressContainer, containerClassName)}>
          <progress className={cx(classes.progress, className)} value={value} />
        </div>
        {!!currentStep && (
          <img
            className={cx(classes.icon, classes.iconStart)}
            src={`${process.env.IMG_ORIGIN}/loyalty-program/loyalty-progress-bar/level${currentStep}.svg`}
            alt={t(`progressbarxp.level${currentStep}`)}
          />
        )}
        {!!nextStep && (
          <img
            className={cx(classes.icon, classes.iconEnd)}
            src={`${process.env.IMG_ORIGIN}/loyalty-program/loyalty-progress-bar/level${nextStep}.svg`}
            alt={t(`progressbarxp.level${nextStep}`)}
          />
        )}
      </div>

      <div className={classes.levels}>
        {!!currentStep && <div>{t(`progressbarxp.level${currentStep}`)}</div>}
        {!!nextStep && <div>{t(`progressbarxp.level${nextStep}`)}</div>}
      </div>
    </>
  )
}

export default ProgressBar
