import { usePathname } from 'next/navigation'
import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import classes from './styles.module.scss'
import PageTitle from 'src/components/PageTitle'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { isSearchPage } from 'utils/route'

type Props = {
  className?: string
}

export default function NoResults({ className }: Props) {
  const t = useTranslation()
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const hideBackButton = !isSearchPage(pathname) || isMobile

  return (
    <section className={cx(classes.root, className)}>
      <PageTitle titleText={t('common.results')} hideBackButton={hideBackButton} />
      <p className={cx(classes.description, classes.withShadow)}>{t('common.search_no_matches')}</p>
    </section>
  )
}
