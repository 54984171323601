import classes from './RewardsListItem.module.scss'
import type { RewardsListItemProps } from './RewardsListItem.types'
import type { PictureProps } from 'components/Picture/Picture.types'
import { ImageType } from 'components/Picture/Picture.types'
import images from 'temp/images.json'
import { Picture } from 'components/Picture'
import React from 'react'
import Button from 'components/Button'
import { format } from 'date-fns'
import { useAppDispatch } from 'store/hooks'
import { goToPlayGame, setActiveGameTags } from 'store/modules/slotGameFlow/actions'
import { useRouter } from 'app/context/navigation'
import { setActiveCurrency } from 'store/modules/currencies/actions'
import { Show } from '@patrianna/core-components'
import { useTranslation } from '@patrianna/core-components'

export function RewardsListItem(gameData: RewardsListItemProps) {
  const { code, title, rounds, currency, tags, expiredAt, roundsRemain, createdAt } = gameData

  const t = useTranslation()
  const dispatch = useAppDispatch()
  const { push } = useRouter()
  const pictureProps: PictureProps = {
    src: `${images.bucket}/tiles-v3/${code}/source.png`,
    alt: title,
    className: classes.tileImg,
    type: ImageType.TILE,
    loading: 'lazy',
  }

  const onClick = () => {
    dispatch(setActiveGameTags({ tags }))
    dispatch(goToPlayGame(gameData, currency, push))
    dispatch(setActiveCurrency({ activeCurrencyId: currency }))
  }

  const remainRoundsExist = Boolean(roundsRemain)

  return (
    <li className={classes.listItem} onClick={onClick}>
      <div className={classes.content}>
        <div className={classes.tileImgBox}>
          <Picture {...pictureProps} />
        </div>

        <div className={classes.details}>
          <header className={classes.header}>
            <span className={classes.highlight}>{rounds}</span>
            <br />
            {t('myRewards.free_spins_card_title')}
          </header>

          <Show when={remainRoundsExist}>
            <p className={classes.roundsRemain}>
              {t('myRewards.free_spins_card_remaining')} <span>{roundsRemain}</span>
            </p>
          </Show>
        </div>
      </div>

      <footer className={classes.footer}>
        <section className={classes.dateSection}>
          {createdAt && (
            <>
              <span>{t('myRewards.free_spins_card_created_at')}&nbsp;</span>
              <span>{format(createdAt, 'dd/MM/yy')}</span>
            </>
          )}

          {expiredAt && (
            <>
              <span>{t('myRewards.free_spins_card_expired_date')}&nbsp;</span>
              <span>{format(expiredAt, 'dd/MM/yy')}</span>
            </>
          )}
        </section>

        <Button className={classes.button}>{t('myRewards.free_spins_card_btn_label')}</Button>
      </footer>
    </li>
  )
}
