import { useEffect, useState } from 'react'
import { intervalToDuration } from 'date-fns'
import { isMillisecondsString } from '@patrianna/shared-utils'
import { useTranslation } from '@patrianna/core-components'

const zeroPad = (num: number) => String(num).padStart(2, '0')

export const useCountdown = (
  eventDate: string | null,
  onlyMinutes: boolean = false,
  onlyHours: boolean = false,
  timePointToEnd?: number
) => {
  const [countdown, setCountdown] = useState('')
  const [lessTimePointToEnd, setTimePointToEnd] = useState(false)
  const t = useTranslation()

  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null

    if (eventDate) {
      const eventTime = isMillisecondsString(eventDate) ? parseInt(eventDate, 10) : new Date(eventDate).getTime()

      const updateCountdown = () => {
        const now = new Date().getTime()
        const distance = eventTime - now

        const timeLeft = intervalToDuration({
          start: now,
          end: eventTime,
        })

        let formattedCountdown = `${
          timeLeft.days ? `${timeLeft.days} ${timeLeft.days == 1 ? t('common.day') : t('common.days')}` : ''
        }
        ${zeroPad(timeLeft.hours)}:${zeroPad(timeLeft.minutes)}:${zeroPad(timeLeft.seconds)}`

        if (onlyHours) {
          const hours = timeLeft.days * 24 + timeLeft.hours
          formattedCountdown = `${zeroPad(hours)}:${zeroPad(timeLeft.minutes)}:${zeroPad(timeLeft.seconds)}`
        }

        if (onlyMinutes) {
          const minutes = timeLeft.days * 24 * 60 + timeLeft.hours * 60 + timeLeft.minutes
          formattedCountdown = `${zeroPad(minutes)}:${zeroPad(timeLeft.seconds)}`
        }

        setCountdown(formattedCountdown)

        // Check a certain time before the end (for example, 5 minutes before the end of the timer)
        if (timePointToEnd && distance < timePointToEnd) {
          setTimePointToEnd(true)
        }

        // Check if the event has passed
        if (distance < 0) {
          clearInterval(interval)
          setCountdown('')
        }
      }

      interval = setInterval(updateCountdown, 1000)

      updateCountdown()
    }

    return () => {
      clearInterval(interval)
    }
  }, [onlyMinutes, eventDate, t, timePointToEnd, onlyHours])

  return {
    pendingCountdown: onlyMinutes ? '00:00' : '24:00:00',
    countdown,
    lessTimePointToEnd,
  }
}
