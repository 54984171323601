import type { RewardListProps } from './RewardsList.types'
import { RewardsListItem } from './components/RewardsListItem'
import classes from './RewardsList.module.scss'
import { getFreeSpinsGames } from './utils/getFreeSpinsGames'
import { useIsMobile } from '@patrianna/shared-hooks'
import cn from 'classnames'
import { useAppSelector } from '@patrianna-payments/shared-store/redeem/store/hooks'
import { getNotCountingSuppliers } from 'store/modules/freeSpins/selectors'

export default function RewardsList({ freeSpins }: RewardListProps) {
  const notCountingSuppliers = useAppSelector(getNotCountingSuppliers)
  const isMobile = useIsMobile()
  const games = getFreeSpinsGames(freeSpins, notCountingSuppliers)

  return (
    <ul
      className={cn(classes.list, {
        [classes.listDesktop]: !isMobile,
        [classes.listMobile]: isMobile,
      })}
    >
      {games.map(({ freeSpinId, ...game }) => {
        return <RewardsListItem key={freeSpinId} {...game} />
      })}
    </ul>
  )
}
