import { Link } from 'components/Link'
import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'

type Props = {
  openSweepRules?: () => void
  scEnabled?: boolean
  className?: string
  // HACK for disabling yieldToMainCallback for payment dialog
  isDisabledYieldToMainCallback?: boolean
  isMobileApp?: boolean
}

export default function ShopTerms(props: Props) {
  const { openSweepRules, scEnabled, className, isMobileApp } = props
  const t = useTranslation()

  return (
    <p className={cx('mt-typography-body2', classes.description, className)}>
      {t('common.at_word')} {t(isMobileApp ? 'common.brand_name_mobile_app' : 'common.brand_name')}{' '}
      {t(scEnabled ? 'shop.shop_terms_sc' : 'shop.shop_terms_gc')} {scEnabled && t('shop.find_sweep_rules')}{' '}
      {scEnabled && (
        <>
          <Link
            href={ROUTES.SWEEPSTAKE_RULES}
            onClick={openSweepRules}
            isDisabledYieldToMainCallback={props.isDisabledYieldToMainCallback}
          >
            {t('common.sweepRules')}
          </Link>
          .{' '}
        </>
      )}
      <Link
        href={ROUTES.TERMS}
        onClick={openSweepRules}
        isDisabledYieldToMainCallback={props.isDisabledYieldToMainCallback}
      >
        {t('common.term_conditions')}
      </Link>{' '}
      {t('common.apply')}
    </p>
  )
}
