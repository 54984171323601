import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useIsAuthPage from 'hooks/useIsAuthPage'
import ButtonLink from 'components/ButtonLink'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { toggleMenu } from 'store/modules/dialog/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import { trackEvent } from 'config/analytic'
import { isAppInitializedSelector } from 'src/store/modules/appConfig/selectors'

type LoggedOutProps = {
  className?: string
}

const SCRATCHFUL = process.env.BRAND_NAME === 'scratchful'

function LoggedOut({ className }: LoggedOutProps) {
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const isAuthPage = useIsAuthPage()
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const handleMenuClose = (eventName: string) => {
    trackEvent(eventName)
    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  return (
    !isAuthPage &&
    appInitialized && (
      <div className={cx(classes.buttonsContainer, className)}>
        <ButtonLink
          href={ROUTES.LOGIN}
          className={cx(classes.loginLink, SCRATCHFUL ? classes.linkContained : classes.linkOutlined)}
          data-test='header-login-btn'
          variant={SCRATCHFUL ? 'contained' : 'outlined'}
          onClick={() => handleMenuClose('login_button')}
        >
          {t('common.login')}
        </ButtonLink>

        <ButtonLink
          href={ROUTES.REGISTER}
          className={classes.signupLink}
          data-test='header-register-btn'
          onClick={() => handleMenuClose('sign_up_button')}
        >
          {t('common.join_now')}
        </ButtonLink>
      </div>
    )
  )
}

export default LoggedOut
