import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import { Link } from 'components/Link'
import RichText from 'components/RichText'
import ShopTerms from 'components/ShopTerms'
import LogoLink from 'containers/LogoLink'
import SocialButtons from 'containers/Menu/SocialButtons'
import { useAppSelector } from 'store/hooks'
import { getIsUserGCModeSelector, shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import classes from './styles.module.scss'

function Footer({ isFooterCentered, className }: { isFooterCentered?: boolean; className?: string }) {
  const t = useTranslation()
  const { socialLinksFeature } = useCountryConfig()
  const { isScEnabled } = useAuth()
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)
  const isGCUser = useAppSelector(getIsUserGCModeSelector)
  const currentYear = new Date().getFullYear()

  return (
    <footer className={classes.root}>
      <div
        className={cx(
          classes.footerContainer,
          'main-container limited',
          { [classes.centered]: isFooterCentered },
          className
        )}
      >
        {!isForceVerifyKYC && (
          <>
            <div className={cx(classes.footerLogoHolder, classes.leftAligned)}>
              <LogoLink className={classes.logo} />
              <p className={classes.text}>{t('footer.copyright', { year: currentYear })}</p>
            </div>
            <ShopTerms scEnabled={isScEnabled} />
            <div className={cx(classes.footerMenuHolder)}>
              <div className={classes.fmBlock}>
                <h3 className={classes.footerTitle}>{t('footer.about_menu_title')}</h3>
                <ul className={cx(classes.footerMenu, classes.buttonsList)}>
                  <li className={classes.fmItem}>
                    <Link href={ROUTES.ABOUT_PAGE} data-test='footer-about-link'>
                      {t('footer.about')}
                    </Link>
                  </li>
                  {isScEnabled && process.env.ZENDESK_SUPPORT_LINK ? (
                    <li className={classes.fmItem}>
                      <a
                        href={process.env.ZENDESK_SUPPORT_LINK}
                        data-test='footer-contact-us-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t('footer.customer_support')}
                      </a>
                    </li>
                  ) : null}
                  <li className={classes.fmItem}>
                    <Link href={ROUTES.TERMS} data-test='footer-terms-link'>
                      {t('footer.terms_of_service')}
                    </Link>
                  </li>
                  {isScEnabled && (
                    <li className={classes.fmItem}>
                      <Link href={ROUTES.SWEEPSTAKE_RULES} data-test='footer-sc-rules-link'>
                        {t('footer.sweepstakes_rules')}
                      </Link>
                    </li>
                  )}
                  <li className={classes.fmItem}>
                    <Link href={ROUTES.PLAYER_SAFETY} data-test='footer-player-safety-link'>
                      {t('footer.player_safety')}
                    </Link>
                  </li>

                  <li className={classes.fmItem}>
                    <Link href={ROUTES.PRIVACY} data-test='footer-privacy-link'>
                      {t('footer.privacy_policy')}
                    </Link>
                  </li>

                  <li className={classes.fmItem}>
                    <Link href={ROUTES.PARTNERS_URL} data-test='footer-partners-link'>
                      {t('footer.partners_link_text')}
                    </Link>
                  </li>

                  {/* <li className={classes.fmItem}> */}
                  {/*  <RichText field={t('footer.blog')} /> */}
                  {/* </li> */}

                  {/* <li className={classes.fmItem}> */}
                  {/*  <Link href='/'>{t('footer.contact_us')}</Link> */}
                  {/* </li> */}
                </ul>
              </div>
              <div className={classes.fmBlock}>
                <RichText
                  field={t('footer.games_menu')}
                  components={{
                    heading4: ({ children }) => <h3 className={classes.footerTitle}>{children}</h3>,
                    list: ({ children }) => <ul className={cx(classes.footerMenu, classes.buttonsList)}>{children}</ul>,
                    listItem: ({ children }) => <li className={classes.fmItem}>{children}</li>,
                  }}
                />
              </div>
            </div>
          </>
        )}
        {/* REENG-595 remove social links for GC user */}
        {socialLinksFeature.enabled && !isGCUser && (
          <>
            {' '}
            <div className={classes.footerMenuHolder}>
              <div className={classes.fmBlock}>
                <h3 className={classes.footerTitle}>{t('footer.follow_us')}</h3>
                <SocialButtons />
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.textBlock}>
              <h3 className={classes.footerTitle}>
                {t('footer.online_support')}{' '}
                <a href={process.env.ZENDESK_SUPPORT_LINK} target='_blank' rel='noreferrer'>
                  {t('footer.contact_us')}
                </a>
              </h3>
              <div className={classes.telBlock}>
                <div className={cx(classes.telWrapper, classes.telOneLine)}>
                  <p className={classes.text}>{t('footer.tel_us')}</p>
                  <p className={classes.text}>
                    <a href={t('footer.us_first_tel')}>{t('footer.us_first_tel_label')}</a>{' '}
                  </p>
                </div>
              </div>
              <RichText
                field={t('footer.tel_caption_text')}
                components={{
                  paragraph: ({ children }) => <p className={classes.text}>{children}</p>,
                }}
              />
            </div>
          </>
        )}
        <div className={classes.divider} />
        <div className={classes.textBlock}>
          <p className={classes.text}>{t('footer.owned_text')}</p>
          {/* <RichText */}
          {/*  field={t('footer.owned_info')} */}
          {/*  components={{ */}
          {/*    paragraph: ({ children }) => <p className={classes.text}>{children}</p>, */}
          {/*  }} */}
          {/* /> */}
          <p className={classes.text}>{t('footer.copyright', { year: currentYear })}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
