import { useCallback } from 'react'
import { graphyteClickThrough } from '@patrianna-payments/analytics/graphyte/graphyteSearch'
import cx from 'classnames'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useTranslation } from '@patrianna/core-components'
import { checkArrayEmptynessNotNull } from 'src/utils/checkArrayEmptynessNotNull'
import { useAppSelector } from 'store/hooks'
import TopGamesSection from 'containers/Layouts/Layout/Header/components/SearchBar/components/TopGamesSection/TopGamesSection'
import { useGraphyteSearch } from 'hooks/useGraphyteSearch'
import SearchInput from 'components/SearchInput'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import GamesSection from './components/GamesSection/GamesSection'
import NoResults from './components/NoResults'
import SearchBarDropdown from './components/SearchBarDropdown'
import { useRouter } from 'app/context/navigation'
import { getRecentlyPlayedGamesSelector } from 'store/modules/categoryGames/selectors'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'

export default function SearchBar() {
  const router = useRouter()
  const t = useTranslation()
  const recentlyPlayedGames: GameProductShort[] = useAppSelector(getRecentlyPlayedGamesSelector)
  const { result, search, setSearch, searchId, userId, isSearching } = useGraphyteSearch()
  const searchPagePath = `${ROUTES.SEARCH}?q=${search}`
  const isSmallDevice = useMediaQuery('sm')
  const isResultsShown = Boolean(search && result?.length)
  const isNoResultsShown = search && checkArrayEmptynessNotNull(result) && !isSearching
  const isRecentlyPlayedShown = (isResultsShown || isNoResultsShown) && recentlyPlayedGames?.length > 0

  const clearSearch = useCallback(() => {
    setSearch('')
  }, [setSearch])

  const trackClick = useCallback(
    (code: string) => {
      graphyteClickThrough(search, searchId, code, userId)
    },
    [search, searchId, userId]
  )

  const onEnterPressed = useCallback(() => {
    if (search.length) {
      router.push(searchPagePath)
    }
  }, [router, searchPagePath, search.length])

  return (
    <div className={cx(classes.searchBar, 'mt-hidden-mobile')}>
      <div className={cx(classes.search, { [classes.searchFullSize]: isSmallDevice })}>
        <SearchInput
          value={search}
          setValue={setSearch}
          onEnterPress={onEnterPressed}
          className={classes.input}
          placeholder={t('common.search_for_games')}
          loading={isSearching}
        />

        <SearchBarDropdown>
          {isResultsShown && (
            <GamesSection
              trackClick={trackClick}
              title={t('common.results')}
              games={result}
              clearSearch={clearSearch}
              limit={9}
              seeMorePath={searchPagePath}
            />
          )}
          {isNoResultsShown && <NoResults className={classes.noResults} />}
          {isRecentlyPlayedShown && (
            <GamesSection
              title={t('common.recently_played')}
              games={recentlyPlayedGames}
              clearSearch={clearSearch}
              limit={3}
            />
          )}
          {isNoResultsShown && <TopGamesSection clearSearch={clearSearch} />}
        </SearchBarDropdown>
      </div>
    </div>
  )
}
