import React from 'react'
import useTopGames from 'hooks/useTopGames'
import GamesSection from 'containers/Layouts/Layout/Header/components/SearchBar/components/GamesSection/GamesSection'
import { useTranslation } from '@patrianna/core-components'

type Props = {
  clearSearch: () => void
}

const TopGamesSection = ({ clearSearch }: Props) => {
  const { topGames } = useTopGames(true)
  const t = useTranslation()

  return <GamesSection title={t('common.top_games')} games={topGames} clearSearch={clearSearch} limit={6} />
}

export default TopGamesSection
