import { type ComponentPropsWithoutRef, type MouseEventHandler, type ImgHTMLAttributes } from 'react'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import classes from './styles.module.scss'

export type BackButtonType = ComponentPropsWithoutRef<'button'> & {
  handler?: MouseEventHandler<HTMLButtonElement>
  color?: 'white' | 'black'
  image?: Partial<ImgHTMLAttributes<HTMLImageElement>>
}

export default function BackButton({ className, children, image = {}, handler, color, ...props }: BackButtonType) {
  return (
    <IconButton
      className={cx(className, classes.root, classes[color || 'black'])}
      onClick={handler}
      data-test='modal-back-button'
      {...props}
    >
      <img
        className={classes.icon}
        src={`${process.env.IMG_ORIGIN}/menu/arrow_scroll.png`}
        alt='arrow-left'
        width={24}
        height={24}
        {...image}
      />

      {children}
    </IconButton>
  )
}
