import cx from 'classnames'
import { Information } from '@patrianna-icons/material-icons'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'
import BalanceAnimation from './BalanceAnimation'

type SweepstakeCurrencyProps = {
  hideMoreInfo?: boolean
  currencyClassName?: string
}

export default function SweepstakeCurrency({ hideMoreInfo, currencyClassName }: SweepstakeCurrencyProps) {
  const dispatch = useAppDispatch()
  const sweepstakeCurrency = useAppSelector(getSweepstakeCurrencySelector)
  const t = useTranslation()

  const showInfoScDialog = () => {
    dispatch(openDialog({ modalName: 'INFO_SC_DIALOG' }))
  }

  if (!sweepstakeCurrency) {
    return null
  }

  return (
    <div className={cx('mt-typography-caption', classes.currencyText, currencyClassName)}>
      <img src={`${process.env.IMG_ORIGIN}/menu/money22.svg?v=3`} className={classes.icon} alt='sc icon' />
      <span>
        {t('common.sweepstakes_coins_sc')} <BalanceAnimation amount={sweepstakeCurrency.amount} decimalPlaces={2} />
      </span>

      {!hideMoreInfo && (
        <button aria-label={t('common.info')} className={classes.info} onClick={showInfoScDialog}>
          <Information className={classes.infoIcon} />
        </button>
      )}
    </div>
  )
}
