import { useRouter } from 'app/context/navigation'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import { isLoggedInSelector, tcCheckVersionSelector } from 'store/modules/user/selectors'
import useGetDataFromLocalStorage from 'hooks/useLocalStorage'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useTranslation } from '@patrianna/core-components'

function ShopMobileLink() {
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const loggedIn = useAppSelector(isLoggedInSelector)
  const isUserRegistered = useGetDataFromLocalStorage('IS_USER_REGISTERED', false)
  const isAcceptedTc = useAppSelector(tcCheckVersionSelector)
  const t = useTranslation()

  if (!appInitialized) {
    return <div className={cx(classes.root, !isAcceptedTc && classes.disable)} />
  }

  return loggedIn ? (
    <div
      className={cx(classes.root, !isAcceptedTc && classes.disable)}
      onClick={() => {
        if (isAcceptedTc) {
          dispatch(openShopHandler({ isMobile: true, routerPush: push }))
        }
      }}
    >
      <div className={classes.animatedBg} />
      <span className={classes.text}>{t('common.buy')}</span>
    </div>
  ) : (
    // @TODO: replace to Link
    <div
      className={classes.root}
      onClick={() => {
        if (isUserRegistered) {
          push(ROUTES.LOGIN)
        } else {
          push(ROUTES.REGISTER)
        }
      }}
    >
      <div className={classes.animatedBg} />
      <span className={classes.text}>{isUserRegistered ? t('common.login') : t('common.join')}</span>
    </div>
  )
}

export default ShopMobileLink
