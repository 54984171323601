import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import { Backspace } from '@patrianna-icons/material-icons'
import classes from './styles.module.scss'
import { IconButton } from '../IconButton'
import { Show } from 'components/Show'
import { useTranslation } from '@patrianna/core-components'

type Props = {
  onInputFocus?: () => void
  onInputBlur?: () => void
  onEnterPress?: () => void
  startAdornment?: boolean
  label?: string
  className?: string
  placeholder?: string
  autoFocus?: boolean
  value: string
  setValue: (value: string) => void
  EndAdornment?: FC<{ onClick: () => void }>
  helperText?: string
  containerClassName?: string
  loading?: boolean
}

function SearchInput({
  onInputFocus,
  onInputBlur,
  onEnterPress,
  label,
  autoFocus,
  className,
  placeholder,
  value,
  setValue,
  EndAdornment,
  containerClassName,
  loading,
}: Props) {
  const [hasFocus, setFocus] = useState(false)
  const t = useTranslation()
  const closeLabel = t('common.close')

  const inputRefCallback = useCallback(
    (inputElement?: HTMLInputElement) => {
      // [FYI]: Safari iOS does not support autofocus on input elements in the meantime. https://medium.com/@brunn/autofocus-in-ios-safari-458215514a5f
      if (autoFocus) {
        const end = value.length

        inputElement?.blur()
        inputElement?.setAttribute('autofocus', '')
        inputElement?.setSelectionRange(end, end)
        inputElement?.focus()
      }
    },
    [autoFocus, value.length]
  )

  const handleInputFocus = async () => {
    setFocus(true)
    onInputFocus?.()
  }

  const handleInputBlur = () => {
    setFocus(false)
    onInputBlur?.()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnterPress?.()
    }
  }

  const handleClose = useCallback(() => {
    setValue('')
  }, [setValue])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const AdornmentNode = useMemo(
    () =>
      EndAdornment ? (
        <EndAdornment onClick={handleClose} aria-label={closeLabel} />
      ) : (
        <IconButton buttonStyle={classes.clearButton} onClick={handleClose} aria-label={closeLabel}>
          <Backspace className={classes.backspaceIcon} />
        </IconButton>
      ),
    [EndAdornment, handleClose, closeLabel]
  )

  return (
    <div
      className={cx(
        classes.root,
        { [classes.focused]: hasFocus, [classes.active]: !!value, [classes.loading]: loading },
        containerClassName
      )}
    >
      <div className={cx(classes.adornment, classes.adornmentStart)}>
        <img
          className={classes.icon}
          src={`${process.env.IMG_ORIGIN}/menu/search-jp.svg`}
          alt='search icon'
          aria-hidden={true}
        />
      </div>
      {!!label && <label className={classes.label}>{label}</label>}
      <input
        ref={inputRefCallback}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        type='text'
        autoCorrect='off'
        autoCapitalize='none'
        name='search-input'
        className={cx(classes.input, className)}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        aria-label={placeholder}
      />

      <Show when={!!value.length && !loading}>
        <div className={cx(classes.adornment, classes.adornmentEnd)}>{AdornmentNode}</div>
      </Show>
    </div>
  )
}

export default SearchInput
